import useTranslation from "next-translate/useTranslation";
import { useForm } from "react-hook-form";
import clsx from "clsx";
import Select from "@/components/common/form/Select";
import usePayment from "@/common/hook/usePayment";
import PureSelect from "@/components/common/form/PureSelect";

const PaymentForm = ({ className, buttonClassName, widgetId, abortFunction, abortClassName }) => {
  const { register, handleSubmit } = useForm();
  const { t } = useTranslation("common");
  const [loading, submitPayment] = usePayment();

  const onSubmit = async (data) => {
    if (loading) {
      return;
    }

    const { paymentMethod, amount } = data;
    await submitPayment(paymentMethod, amount, widgetId);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={`flex flex-col text-left items-start mt-6 ${className}`}>
      <PureSelect id="amount" register={register} className="w-full">
        <option value="5">5 €</option>
        <option value="10">10 €</option>
        <option value="20">20 €</option>
        <option value="25">25 €</option>
        <option value="35">35 €</option>
        <option value="50">50 €</option>
        <option value="75">75 €</option>
        <option value="100">100 €</option>
        <option value="150">150 €</option>
        <option value="200">200 €</option>
      </PureSelect>
      <Select
        id="paymentMethod"
        label={t`payment.method`}
        labelClassName="text-grey-800 font-normal"
        className="mt-6 w-full"
        register={register}
      >
        <option value="paypal">PayPal</option>
        <option value="sofort">Sofort / Klarna</option>
        <option value="paypal">{t`payment.credit-card`}</option>
      </Select>
      {abortFunction ? (
        <div className={clsx("flex gap-3 mt-12", abortClassName)}>
          <button
            type="submit"
            className={clsx("button hover-orange", buttonClassName, loading && "gap-2 flex-center")}
            disabled={loading}
          >
            {loading && <span className="button-spinner" />}
            {t`payment.charge-now`}
          </button>
          <button
            type="button"
            className={clsx("font-normal text-grey-800 button-text", buttonClassName)}
            disabled={loading}
            onClick={abortFunction}
          >
            {t`abort`}
          </button>
        </div>
      ) : (
        <button
          type="submit"
          className={clsx("mt-12 button hover-orange", buttonClassName, loading && "gap-2 flex-center")}
          disabled={loading}
        >
          {loading && <span className="button-spinner" />}
          {t`payment.charge-now`}
        </button>
      )}
    </form>
  );
};

export default PaymentForm;

import { useCallback } from "react";
import useUser from "@/common/hook/user/useUser";
import request from "@/utils/request";

function useUserSetState() {
  const { user, mutate } = useUser();

  return useCallback(
    async (state) => {
      try {
        const { status } = await request.post(`/consultants/${user.id}/state`, { state });
        if (status === 200) {
          mutate((prev) => ({ ...prev, state, stateChangedAt: new Date().toISOString() }), false);
        }
        // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    [user?.id, mutate]
  );
}

export default useUserSetState;

import { useState, memo, useRef, useEffect } from "react";
import clsx from "clsx";
import addNoScroll from "@/utils/addNoScroll";
import useOutsideClick from "@/common/hook/useOutsideClick";
import IconClose from "@/common/icons/close.svg";

const Sidebar = ({ icon: Icon, children, action }) => {
  const [isOpen, setOpen] = useState(false);
  const buttonRef = useRef();
  // h-24 top-24 for header height

  const containerRef = useOutsideClick(
    isOpen &&
      ((target) => {
        if (buttonRef.current && !buttonRef.current.contains(target)) setOpen(false);
      })
  );

  useEffect(() => {
    addNoScroll(isOpen);

    return () => {
      addNoScroll(false);
    };
  }, [isOpen]);

  return (
    <div>
      <div className="relative">
        <button
          className={clsx("button-icon", isOpen && "text-blue border-blue")}
          onClick={() => setOpen((prev) => !prev)}
          ref={buttonRef}
          type="button"
          aria-label="Toggle Menu"
        >
          <Icon className="w-4 md:w-6 h-4 md:h-6" />
        </button>
        {isOpen && (
          <div className="hidden sm:block absolute -bottom-7 left-3.5 border-r-8 border-b-4 border-l-8 border-white border-solid border-t-white border-b-white border-r-transparent border-l-transparent" />
        )}
      </div>
      <div
        className={clsx(
          "flex fixed top-16 sm:top-24 right-0 bottom-0 left-0 justify-end transition-all duration-300 ease-linear cursor-auto bg-black/60",
          isOpen ? "visible opacity-100" : "invisible opacity-0"
        )}
      >
        <div
          ref={containerRef}
          className={clsx(
            "overflow-y-auto relative w-80 h-full bg-white transition-all duration-300 ease-linear",
            isOpen ? "sm:right-0" : "sm:-right-1/4"
          )}
        >
          <aside className="flex flex-col p-5">
            <div className={clsx("flex", action ? "justify-between" : "justify-end")}>
              {action}
              <button
                type="button"
                className="self-end w-8 h-8 rounded-full button-icon"
                onClick={() => setOpen(false)}
              >
                <IconClose className="w-4 h-4 text-grey-600 group-hover:text-blue" />
              </button>
            </div>
            {children}
          </aside>
        </div>
      </div>
    </div>
  );
};

export default memo(Sidebar);

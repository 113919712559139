const className = "w-2 h-2 bg-grey-600 rounded-full animate-pulse";

const AnimationThreeBalls = () => (
  <div className="space-x-1 h-5 flex-center">
    <span className={`${className} animation-delay-300`} />
    <span className={`${className} animation-delay-600`} />
    <span className={`${className} animation-delay-900`} />
  </div>
);

export default AnimationThreeBalls;

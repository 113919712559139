import { useState } from "react";
import dynamic from "next/dynamic";
import IconMenu from "@/common/icons/menu.svg";
import Sidebar from "@/components/core/Header/Sidebar";
import IconChevronLeft from "@/common/icons/chevron-left.svg";
import useOnceInView from "@/common/hook/useOnceInView";

const MainMenuContainer = dynamic(() => import("./MainMenuContainer"));

const MainMenu = () => {
  const [isViewed, ref] = useOnceInView();
  const [selectedCategory, setSelectedCategory] = useState(null);

  return (
    <Sidebar
      icon={IconMenu}
      action={
        selectedCategory !== null && (
          <button
            type="button"
            className="self-end w-8 h-8 rounded-full button-icon"
            onClick={() => setSelectedCategory(null)}
            aria-label="Back"
          >
            <IconChevronLeft className="w-4 h-4" />
          </button>
        )
      }
    >
      <div ref={ref}>
        {isViewed && (
          <MainMenuContainer selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />
        )}
      </div>
    </Sidebar>
  );
};

export default MainMenu;

import { memo, useCallback, useEffect } from "react";
import useTranslation from "next-translate/useTranslation";
import clsx from "clsx";
import useConversations from "@/common/hook/header/useConversations";
import useSocket from "@/common/hook/useSocket";
import insertIntoArray from "@/utils/insertIntoArray";
import IconClose from "@/common/icons/close.svg";
import ConversationItem from "@/components/core/Header/Chat/Conversations/ConversationItem";
import useChatReceiverId from "@/common/hook/useChatReceiverId";
import { setCache } from "@/utils/cache";
import CacheKeys from "@/common/models/CacheKeys";

const ConversationList = ({ isMobileChat, setMobileChat }) => {
  const { t } = useTranslation("common");
  const { conversations, mutate } = useConversations();
  const [currentReceiverId, setCurrentReceiverId] = useChatReceiverId();
  const closeChat = useCallback(() => setCache(CacheKeys.CHAT_ACTIVE, false), []);
  const socket = useSocket();

  const updateConversation = useCallback(
    (conversationId, value) => {
      const index = conversations.findIndex((c) => c.id === conversationId);
      if (index !== -1) {
        mutate(insertIntoArray(conversations, index, { ...conversations[index], ...value }), false);
      }
    },
    [conversations, mutate]
  );

  useEffect(() => {
    socket.on("chat.typing", ({ senderId, isTyping }) => updateConversation(senderId, { isTyping }));
    socket.on("chat.consultantUpdate", ({ userId, state }) => updateConversation(userId, { state }));

    return () => {
      socket.off("chat.typing");
      socket.off("chat.consultantUpdate");
    };
  }, [socket, updateConversation]);

  useEffect(() => {
    if (currentReceiverId === null && (conversations?.length ?? 0) !== 0) {
      setCurrentReceiverId(conversations[0].id);
    }
  }, [currentReceiverId, conversations, setCurrentReceiverId]);

  return (
    <div
      className={clsx(
        "flex-col w-screen md:w-[22rem] border-r border-grey-200",
        isMobileChat ? "hidden md:flex" : "flex"
      )}
    >
      <div className="flex flex-shrink-0 justify-between items-center py-3 px-4 h-16 text-center border-b border-grey-200">
        <span className="text-base font-semibold text-grey-900">{t`chat.title`}</span>
        <button
          type="button"
          className="md:hidden w-10 h-10 rounded-full button-icon"
          onClick={closeChat}
          aria-label="Close Chat"
        >
          <IconClose className="w-5 h-5" />
        </button>
      </div>
      <div className="flex overflow-y-auto flex-col thin-scrollbar">
        {conversations?.map((conversation) => (
          <ConversationItem key={conversation.id} conversation={conversation} setMobileChat={setMobileChat} />
        ))}
      </div>
    </div>
  );
};

const Conversations = memo(ConversationList);
export default Conversations;

import { useState } from "react";
import ReactHowler from "react-howler";
import { mutate } from "swr";
import { useForm } from "react-hook-form";
import useTranslation from "next-translate/useTranslation";
import PureSelect from "@/components/common/form/PureSelect";
import request from "@/utils/request";
import useUser from "@/common/hook/user/useUser";

const NewVoiceRecordSubmit = ({ audioBlob, onClose, reset, playing, stopPlaying }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { user } = useUser();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("common");

  const onSubmit = async (data) => {
    if (loading) {
      return;
    }

    const lifetimeDays = data.lifetime;

    const formData = new FormData();
    formData.append("file", audioBlob);
    formData.append("lifetime", lifetimeDays * 86400);

    setLoading(true);

    try {
      await request.post("/consultant-sound", formData);
      mutate(`/consultant-sounds/${user?.id}`);
      setLoading(false);
      onClose();
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col items-center space-y-1">
      <PureSelect
        register={register}
        rules={{ required: true }}
        id="lifetime"
        className={`mb-3 ${errors.lifetime ? "!border-red" : ""}`}
      >
        <option value="">{t`voice-message.lifetime`}</option>
        <option value="1">{t("format.time.day", { count: 1 })}</option>
        <option value="2">{t("format.time.day", { count: 3 })}</option>
        <option value="7">{t("format.time.day", { count: 7 })}</option>
        <option value="32000">{t`unlimited`}</option>
      </PureSelect>
      <button className="py-3 px-12 text-xl bg-purple-900 rounded-full button-text" type="submit">
        {t`publish`}
      </button>
      <button className="button-text" type="button" onClick={reset}>
        {t`voice-message.record-again`}
      </button>
      <ReactHowler
        src={URL.createObjectURL(audioBlob)}
        playing={playing}
        onEnd={stopPlaying}
        format={["mp3", "webm", "ogg"]}
      />
    </form>
  );
};

export default NewVoiceRecordSubmit;

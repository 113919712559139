import { useState } from "react";
import dynamic from "next/dynamic";
import IconChat from "@/common/icons/chat.svg";
import DropdownMenu from "@/components/common/DropdownMenu";
import useOnceInView from "@/common/hook/useOnceInView";

const ChatModalContainer = dynamic(() => import("./ChatModalContainer"));

const ChatModal = () => {
  const [isViewed, ref] = useOnceInView();
  const [isOpen, setOpen] = useState(false);

  return (
    <DropdownMenu
      isOpen={isOpen}
      setOpen={setOpen}
      title={<IconChat className="w-4 md:w-6 h-4 md:h-6" />}
      containerRef={ref}
    >
      {isViewed && <ChatModalContainer setOpen={setOpen} />}
    </DropdownMenu>
  );
};

export default ChatModal;

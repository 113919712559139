import { useState } from "react";
import useTranslation from "next-translate/useTranslation";
import MessageSystem from "@/components/core/Header/Chat/Messages/MessageSystem";
import PaymentForm from "@/components/common/PaymentForm";
import useChatReceiverId from "@/common/hook/useChatReceiverId";

const MessageNoBalance = ({ message }) => {
  const [currentReceiverId] = useChatReceiverId();
  const [openForm, setOpenForm] = useState(false);
  const { t } = useTranslation("common");

  return (
    <MessageSystem message={message || t`chat.no-credit`} showTimestamp={false}>
      {openForm ? (
        <PaymentForm
          widgetId={currentReceiverId}
          className="mt-4"
          buttonClassName="mt-4 -mb-4 text-xs font-bold underline cursor-pointer"
        />
      ) : (
        <button
          type="button"
          className="mt-4 -mb-4 text-xs font-bold underline disabled:opacity-50 cursor-pointer button-text"
          onClick={() => setOpenForm(true)}
        >
          {t`top-up-credit`}
        </button>
      )}
    </MessageSystem>
  );
};

export default MessageNoBalance;

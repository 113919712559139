import useTranslation from "next-translate/useTranslation";
import clsx from "clsx";
import IconState from "@/common/icons/online-state.svg";
import UserState from "@/common/models/UserState";
import Avatar from "@/components/common/Avatar";
import useChatReceiverId from "@/common/hook/useChatReceiverId";

const ConversationItem = ({ conversation, setMobileChat }) => {
  const { t } = useTranslation("common");
  const [currentReceiverId, setCurrentReceiverId] = useChatReceiverId();

  const onClick = () => {
    if (currentReceiverId !== conversation.id) {
      setCurrentReceiverId(conversation.id);
    }
    setMobileChat(true);
  };

  return (
    <div
      role="presentation"
      className={clsx(
        "flex items-center p-2 space-x-2.5 hover:bg-blue-800 cursor-pointer select-none",
        currentReceiverId === conversation.id && "bg-blue-800"
      )}
      onClick={onClick}
    >
      <div className="relative flex-shrink-0 w-20 h-20">
        <Avatar image={conversation.image} author={conversation.name} className="w-full h-full text-4xl" />
        <IconState className={`h-auto -bottom-0.5 absolute left-1 w-8 ${UserState[conversation.state].textColor}`} />
      </div>
      <div className="flex flex-col min-w-0 text-sm">
        <span className="w-full font-semibold text-grey-900 truncate">{conversation.name}</span>
        <span className="w-full text-grey-900 truncate">
          {conversation.isTyping ? `${t`chat.typing`}...` : conversation.lastMessage}
        </span>
        {conversation.tariff && (
          <span className="w-full text-grey-900 truncate">
            {t("format.tariff", { tariff: conversation.tariff / 100 })}
          </span>
        )}
      </div>
    </div>
  );
};

export default ConversationItem;

import clsx from "clsx";
import Image from "next/image";

const FooterCardContent = ({ children, className, image, active, after }) => (
  <div
    className={clsx(
      "relative",
      className,
      active ? "lg:col-auto col-start-1 col-end-3 lg:row-auto row-start-2" : "hidden lg:block"
    )}
  >
    <Image
      src={image}
      alt="Footer Blob"
      placeholder="blur"
      blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII="
      layout="fill"
    />
    <ul className="relative lg:p-12 py-6 px-5 space-y-6">{children}</ul>
    {after}
  </div>
);

export default FooterCardContent;

import { useMemo } from "react";
import clsx from "clsx";
import useTranslation from "next-translate/useTranslation";
import SearchBarConsultantCard from "@/components/common/SearchBar/SearchBarConsultantCard";
import SearchBarBlogCard from "@/components/common/SearchBar/SearchBarBlogCard";
import IconSearch from "@/common/icons/search.svg";

const SearchBarLiveContainer = ({ loading, data, isOpen, closeRoutingOnBlur, search, cursor, responsive = true }) => {
  const { t } = useTranslation("common");
  const isEmpty = useMemo(
    () => (data ? Object.values(data).every((item) => item === null || item.length === 0) : true),
    [data]
  );

  // HACK : BORDER (DONT DELETE BORDER LEFT)
  return (
    <div
      className={clsx(
        "overflow-y-auto absolute top-full -left-4 lg:left-0 z-10 pr-6 lg:mx-0 mt-3 space-y-12 w-screen lg:w-full max-h-[32rem] bg-white border-l-[1.5rem] lg:border-l-0 border-transparent shadow-lg",
        (!isOpen || (isEmpty && !loading)) && "hidden",
        responsive ? "lg:py-6 lg:px-12" : "lg:p-6"
      )}
    >
      {data ? (
        <>
          {data.suggest.length > 0 && (
            <ul>
              {data.suggest.map((s, index) => (
                <li
                  role="presentation"
                  onClick={() => search(s)}
                  className={clsx(
                    "flex items-center p-2 text-grey-800 hover:bg-grey-100 focus:bg-grey-100 cursor-pointer",
                    cursor === index && "bg-grey-100"
                  )}
                  key={s}
                >
                  <IconSearch className={clsx("flex-shrink-0 w-3 h-3", responsive && "lg:w-4 lg:h-4")} />
                  <span className={clsx("ml-4 text-sm", responsive && "lg:text-base")}>{s}</span>
                </li>
              ))}
            </ul>
          )}
          {data.consultants && (
            <section>
              <h4
                className={clsx("mb-4 font-semibold text-orange", responsive && "lg:mb-6")}
              >{t`content-types.consultant`}</h4>
              {data.consultants.map((consultant) => (
                <SearchBarConsultantCard
                  key={consultant.id}
                  consultant={consultant}
                  closeRoutingOnBlur={closeRoutingOnBlur}
                  responsive={responsive}
                />
              ))}
            </section>
          )}
          {data.blogs && (
            <section>
              <h4
                className={clsx("mb-4 font-semibold text-orange", responsive && "lg:mb-6")}
              >{t`content-types.blog`}</h4>
              {data.blogs.map((blog) => (
                <SearchBarBlogCard
                  key={blog.id}
                  blog={blog}
                  closeRoutingOnBlur={closeRoutingOnBlur}
                  responsive={responsive}
                />
              ))}
            </section>
          )}
        </>
      ) : (
        loading && <div className="spinner" />
      )}
    </div>
  );
};

export default SearchBarLiveContainer;

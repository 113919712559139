import clsx from "clsx";
import useTranslation from "next-translate/useTranslation";
import { Fragment } from "react";
import UserState from "@/common/models/UserState";
import IconChevronRight from "@/common/icons/chevron-right.svg";
import useUser from "@/common/hook/user/useUser";
import useUserSetState from "@/common/hook/user/useUserSetState";

const DROPDOWN_OPTIONS = {
  [UserState.ONLINE.value]: [UserState.OFFLINE],
  [UserState.OFFLINE.value]: [UserState.ONLINE],
  [UserState.CALL.value]: [],
  DEFAULT: [UserState.ONLINE, UserState.OFFLINE],
};

const STATE_COLORS = {
  [UserState.ONLINE.value]: "bg-green hover:bg-green-900",
};

const StateDropdown = () => {
  const { t } = useTranslation("common");
  const { user = {} } = useUser();
  const setState = useUserSetState();

  return (
    <div
      className={clsx(
        "group flex relative justify-between items-center p-3 w-44 text-sm font-semibold text-white rounded-lg transition-colors duration-300 cursor-pointer",
        STATE_COLORS[user.state] ?? "bg-grey-500 hover:bg-grey-400"
      )}
    >
      <span className="text-center">
        {t(UserState[user.state]?.languageKey, null, { fallback: UserState.OFFLINE.languageKey })}
      </span>
      <IconChevronRight className="w-5 h-5 rotate-90 group-hover:-rotate-90 fill-current" />
      <div className="absolute top-full left-0 invisible group-hover:visible py-3 space-y-2 w-full bg-white shadow opacity-0 group-hover:opacity-100 transition-opacity duration-300">
        {(DROPDOWN_OPTIONS[user.state] || DROPDOWN_OPTIONS.DEFAULT).map((opt, index) => (
          <Fragment key={opt.value}>
            {index > 0 && (
              <div className="flex-center">
                <hr className="w-28 border-grey-100" />
              </div>
            )}
            <div
              role="presentation"
              className="py-3 w-full text-center text-grey-800 hover:bg-grey-100"
              onClick={() => setState(opt.value)}
            >
              {t(opt.languageKey)}
            </div>
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default StateDropdown;

import Image from "next/image";

const FooterItem = ({ title, description }) => (
  <li className="flex items-center min-w-0">
    <div className="relative flex-shrink-0 w-8 lg:w-10 h-8 lg:h-10">
      <Image src="/check-circle.png" alt="Circle Check" layout="fill" />
    </div>
    <div className="ml-6">
      <strong className="block text-sm lg:text-base">{title}</strong>
      <p className="text-sm lg:text-base text-grey-800">{description}</p>
    </div>
  </li>
);

export default FooterItem;

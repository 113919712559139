const MessageSystem = ({ children, message, showTimestamp, timestamp }) => (
  <div className="flex-col text-center flex-center">
    {showTimestamp && <div className="flex justify-center text-xs font-bold text-grey-600 uppercase">{timestamp}</div>}
    <div className="relative flex-col px-16 pt-6 pb-4 mt-5 mb-2.5 text-base rounded-2xl border-2 border-grey-200 flex-center">
      <img
        className="absolute -top-4 left-1/2 w-8 h-8 rounded-full -translate-x-1/2"
        src="https://vialantis.fra1.cdn.digitaloceanspaces.com/static/logo/viallogo.png"
        alt="Vialantis Logo"
      />
      <span className="text-grey-900">{message}</span>
      {children}
    </div>
  </div>
);

export default MessageSystem;

import { useCallback, useState } from "react";
import dynamic from "next/dynamic";
import Popup from "@/components/common/Popup";

const StepRating = dynamic(() => import("./StepRating"));
const StepCategory = dynamic(() => import("./StepCategory"));
const StepTip = dynamic(() => import("./StepTip"));

const STEPS = [StepRating, StepCategory, StepTip];

const RatingDialog = ({ onClose, phoneCallId, mutate, consultantName }) => {
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({ rate: 0, ratingComplimentItems: [], comment: "" });

  const nextStep = useCallback(() => setStep((prev) => prev + 1), [setStep]);
  const setForm = useCallback(
    (name, value) => {
      setFormData((data) => ({ ...data, [name]: value }));
    },
    [setFormData]
  );

  const StepComponent = STEPS[step];

  return (
    <Popup onClose={onClose} size="lg" className="text-grey">
      <StepComponent
        nextStep={nextStep}
        formData={formData}
        setForm={setForm}
        setFormData={setFormData}
        phoneCallId={phoneCallId}
        consultantName={consultantName}
        mutate={mutate}
        closeDialog={onClose}
      />
    </Popup>
  );
};

export default RatingDialog;

import { useEffect, useMemo, useRef } from "react";
import { mutate } from "swr";
import clsx from "clsx";
import useSocket from "@/common/hook/useSocket";
import ChatConversationListItem from "@/common/models/ChatConversationListItem";
import useConversations from "@/common/hook/header/useConversations";
import useChatMessages from "@/common/hook/header/useChatMessages";
import ChatMessage from "@/common/models/ChatMessage";
import AnimationThreeBalls from "@/components/common/AnimationThreeBalls";
import MessagesHeader from "@/components/core/Header/Chat/Messages/MessagesHeader";
import useUser from "@/common/hook/user/useUser";
import MessageInput from "./MessageInput";
import MessageInfoSection from "./MessageInfoSection";
import Message from "./Message";
import renderMessages from "./renderMessages";
import useChatReceiverId from "@/common/hook/useChatReceiverId";

const Messages = ({ isMobileChat, setMobileChat }) => {
  const [currentReceiverId] = useChatReceiverId();
  const socket = useSocket();
  const {
    conversations,
    mutate: mutateConversations,
    currentReceiver,
    currentReceiverIndex,
  } = useConversations(currentReceiverId);
  const { user } = useUser();
  const messagesEndRef = useRef(null);
  const { messages, addMessage, loadMore } = useChatMessages(currentReceiverId);

  const renderedMessages = useMemo(() => (messages !== null ? renderMessages(messages) : []), [messages]);

  useEffect(() => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  }, [messagesEndRef, messages, currentReceiverId]);

  useEffect(() => {
    socket.on("chat.message", (messageData) => {
      if (currentReceiverId === null || messages === null) {
        return;
      }

      if (currentReceiverId === messageData.senderId) {
        // ADD MESSAGE DIRECTLY
        addMessage(ChatMessage(messageData.message, currentReceiverId, user.id, false, messageData.type));
        // IF NOT NORMAL MESSAGE CHECK INSTANLY CHAT CHECK
        if (messageData.type !== null) {
          mutate(`/chat-check/${currentReceiverId}`).then();
        }
      }

      if (conversations && user.id !== messageData.senderId) {
        // SET LAST MESSAGE
        const conversationsCopy = conversations.slice();
        if (currentReceiverIndex !== -1) {
          conversationsCopy[currentReceiverIndex] = { ...currentReceiver, lastMessage: messageData.message };
        } else {
          conversationsCopy.unshift(
            ChatConversationListItem(messageData.senderId, messageData.name, messageData.image, messageData.message)
          );
        }
        mutateConversations(conversationsCopy, false);
      }
    });

    return () => socket.off("chat.message");
  }, [
    socket,
    conversations,
    user,
    messages,
    currentReceiverId,
    currentReceiverIndex,
    addMessage,
    currentReceiver,
    mutateConversations,
  ]);

  const onScroll = (e) => {
    // LOAD MORE MESSAGES (loadMore is null no fetching data)
    if (e.target.scrollTop === 0 && loadMore !== null) {
      loadMore();
    }
  };

  return (
    <div className={clsx("flex-col flex-grow w-screen md:w-auto", isMobileChat ? "flex" : "hidden md:flex")}>
      <MessagesHeader setMobileChat={setMobileChat} receiverName={messages?.receiver.name || currentReceiver?.name} />
      <div className="overflow-y-auto flex-grow p-2.5 pb-0" onScroll={onScroll}>
        {renderedMessages}
        <MessageInfoSection />
        {currentReceiver?.isTyping && (
          <Message
            author={currentReceiver.name}
            image={currentReceiver.image}
            showTimestamp={false}
            isMine={false}
            start
            end
            message={<AnimationThreeBalls />}
          />
        )}
        {/* HACK FOR PADDING ON OVERFLOW */}
        <div ref={messagesEndRef} className="block pb-2.5" />
      </div>
      <MessageInput />
    </div>
  );
};
export default Messages;

import useSWR from "swr";
import { useMemo } from "react";
import request from "@/utils/request";
import useUser from "@/common/hook/user/useUser";
import useIsChatActive from "@/common/hook/useIsChatActive";

const REFRESH_INTERVAL = 10 * 1000; // 10 seconds

const ERROR_CODES = {
  NO_SESSION: 402,
  NO_BALANCE: 406,
};

const fetcherPost = (url) => request.post(url).then((res) => res.data);

export default function useChatChecker(currentReceiverId, currentReceiverTariff) {
  const { user } = useUser();
  const [isChatActive] = useIsChatActive();
  const { data, error } = useSWR(isChatActive && currentReceiverId ? `/chat-check/${currentReceiverId}` : null, {
    onErrorRetry(err, key, config, revalidate, { retryCount }) {
      if (!Object.values(ERROR_CODES).includes(error.response.status)) return;

      // Retry
      setTimeout(() => revalidate({ retryCount, dedupe: true }), REFRESH_INTERVAL);
    },
    refreshInterval: REFRESH_INTERVAL,
    errorRetryInterval: REFRESH_INTERVAL,
    dedupingInterval: REFRESH_INTERVAL - 1000,
    fetcher: fetcherPost,
  });

  const hasNotBalance = useMemo(() => {
    if (error && error.response && error.response.status === ERROR_CODES.NO_BALANCE) {
      return true;
    }

    return (currentReceiverTariff ?? 0) > user?.balanceAmount;
  }, [error, currentReceiverTariff, user]);

  const hasSession = !error && data && data.success;
  const connectedAt = hasSession && data.connectedAt;

  return { hasNotBalance, hasSession, connectedAt };
}

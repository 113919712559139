import clsx from "clsx";
import { useCallback } from "react";
import useUser from "@/common/hook/user/useUser";
import request from "@/utils/request";

const PhoneNumbers = ({ className }) => {
  const { user = {}, mutate } = useUser();

  const setMainPhoneNumber = useCallback(
    async (id) => {
      try {
        const response = await request.put(`/phone-numbers/${id}`, { main: true });
        if (response.status === 200) {
          mutate(
            (prev) => ({ ...prev, phoneNumbers: user.phoneNumbers.map((pn) => ({ ...pn, main: pn.id === id })) }),
            false
          );
        }
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
    [mutate, user?.phoneNumbers]
  );

  return (
    <div className={clsx("space-y-3", className)}>
      {user.phoneNumbers?.map((p) => (
        <div
          role="presentation"
          className="flex items-center space-x-2.5 cursor-pointer"
          key={p.id}
          onClick={p.main ? null : () => setMainPhoneNumber(p.id)}
        >
          <span
            className={clsx("w-4 h-4 rounded-full border-2 border-grey-900", p.main ? "bg-grey-900" : "bg-white")}
          />
          <div className="text-base text-grey-900">{p.number}</div>
        </div>
      ))}
    </div>
  );
};

export default PhoneNumbers;

import useTranslation from "next-translate/useTranslation";
import Image from "next/image";
import Router from "next/router";
import clsx from "clsx";
import IconArrowRight from "@/common/icons/arrow-right.svg";
import IconChevronRight from "@/common/icons/chevron-right.svg";

const SearchBarBlogCard = ({ blog, closeRoutingOnBlur, responsive }) => {
  const { t } = useTranslation();

  return (
    <div
      className="flex justify-between items-center p-1 mt-6 bg-white hover:bg-grey-100 rounded-lg"
      role="presentation"
      onMouseDown={() => {
        closeRoutingOnBlur();
        Router.push(`/blog/${blog.slug}`);
      }}
    >
      <div className={clsx("flex gap-6 items-center w-full", responsive && "lg:w-1/3")}>
        <div
          className={clsx(
            "overflow-hidden relative flex-shrink-0 w-16 h-16 rounded-full border-4 border-white",
            responsive && "lg:w-24 lg:h-24"
          )}
        >
          <Image src={blog.picture} layout="fill" alt={blog.title} objectFit="cover" />
        </div>
        <div className="w-full">
          <div className={clsx("font-semibold", responsive && "lg:text-xl")}>{blog.category}</div>
          <span className="mt-3 text-xs text-purple">
            {t("common:format.from", { from: blog.author || "Vialantis" })}
          </span>
        </div>
      </div>
      <div className="w-1/2">
        <p className={clsx("hidden text-xs text-grey-800", responsive && "lg:block")}>{blog.title}</p>
        <p className={clsx("hidden mt-3 text-xs leading-5 text-grey-700", responsive && "lg:line-clamp-2")}>
          {blog.description}
        </p>
      </div>
      <button
        type="button"
        className={clsx(
          "hidden p-2 space-x-2 text-green hover:text-green-900 button-text",
          responsive && "lg:flex-center"
        )}
      >
        <span>{t`common:read-more`}</span>
        <IconArrowRight />
      </button>
      <button
        className={clsx("flex-shrink-0 button-icon", responsive && "lg:hidden")}
        type="button"
        aria-label={t`common:read-more`}
      >
        <IconChevronRight className="w-4 lg:w-8 h-4 lg:h-8" />
      </button>
    </div>
  );
};

export default SearchBarBlogCard;

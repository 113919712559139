import { useForm } from "react-hook-form";
import { createElement, useCallback, useState } from "react";
import clsx from "clsx";
import dynamic from "next/dynamic";
import Popup from "@/components/common/Popup";
import PureInputPhoneNumber from "@/components/common/form/PureInputPhoneNumber";
import useAddPhoneNumber from "@/common/hook/useAddPhoneNumber";

const IconCheckMark = dynamic(() => import("@/common/icons/check-mark.svg"));
const IconClose = dynamic(() => import("@/common/icons/close.svg"));

// TODO : I18N
const AddPhoneNumberPopup = ({ onClose }) => {
  const { control, handleSubmit } = useForm();
  const [success, setSuccess] = useState(false);
  const handleSuccess = useCallback(() => {
    setSuccess(true);
    setTimeout(onClose, 2000);
  }, [onClose]);

  const { loading, addNewPhoneNumber, error } = useAddPhoneNumber(handleSuccess);

  return (
    <Popup size="xs" className="lg:px-6" onClose={onClose}>
      {(success || error) && (
        <div
          className={clsx(
            "flex gap-4 items-center py-2 px-4 mb-4 w-full rounded-lg",
            success && "text-green bg-green-800",
            error && "text-red bg-red-900"
          )}
        >
          {createElement(success ? IconCheckMark : IconClose, { className: "w-4 h-4" })}
          {success ? "Telefonnummer erfolgreich hinzugefügt." : error}
        </div>
      )}
      <div className="text-xl lg:text-3xl font-semibold text-center">Neue Rufnummer hinzufügen</div>
      <form className="mt-8 w-full" onSubmit={handleSubmit((data) => addNewPhoneNumber(data.phoneNumber))}>
        <PureInputPhoneNumber control={control} disabled={success || loading} id="phoneNumber" />
        <button
          className="block gap-2 mx-auto mt-8 hover-orange button flex-center"
          type="submit"
          disabled={success || loading}
        >
          {loading && <span className="button-spinner" />}
          Füge jetzt deine Nummer hinzu
        </button>
      </form>
    </Popup>
  );
};

export default AddPhoneNumberPopup;

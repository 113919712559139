import { useState } from "react";
import useNoScroll from "@/common/hook/useNoScroll";
import Conversations from "@/components/core/Header/Chat/Conversations";
import Messages from "@/components/core/Header/Chat/Messages";

const ChatDialog = () => {
  const [isMobileChat, setMobileChat] = useState(false);
  useNoScroll();

  return (
    <div className="fixed inset-0 z-50 w-screen h-screen bg-white md:bg-black/90 flex-center">
      <div className="flex w-full md:w-[920px] h-full md:max-h-[80%] bg-white md:rounded-lg">
        <Conversations isMobileChat={isMobileChat} setMobileChat={setMobileChat} />
        <Messages isMobileChat={isMobileChat} setMobileChat={setMobileChat} />
      </div>
    </div>
  );
};

export default ChatDialog;

import { useCallback, useState } from "react";
import { mutate } from "swr";
import useTranslation from "next-translate/useTranslation";
import MessageSystem from "@/components/core/Header/Chat/Messages/MessageSystem";
import request from "@/utils/request";
import useConversations from "@/common/hook/header/useConversations";
import useChatReceiverId from "@/common/hook/useChatReceiverId";
import { setCache } from "@/utils/cache";
import CacheKeys from "@/common/models/CacheKeys";

const MessageNoSession = () => {
  const { t } = useTranslation("common");
  const [currentReceiverId] = useChatReceiverId();
  const { currentReceiver } = useConversations(currentReceiverId);
  const [isLoading, setLoading] = useState(false);
  const closeChat = useCallback(() => setCache(CacheKeys.CHAT_ACTIVE, false), []);

  const onClick = useCallback(async () => {
    if (!isLoading && currentReceiverId !== null) {
      setLoading(true);
      request
        .get(`/chat-ping/${currentReceiverId}`)
        .then(() => mutate(`/chat-check/${currentReceiverId}`))
        .catch((e) => console.log("Chat Ping Error", e))
        .finally(() => setLoading(false));
    }
  }, [currentReceiverId, isLoading]);

  return (
    <MessageSystem
      message={t("chat.start-chat-dialog", {
        name: currentReceiver?.name,
        tariff: t("format.tariff", { tariff: currentReceiver?.tariff / 100 }),
      })}
      showTimestamp={false}
    >
      <div className="flex mt-4 -mb-4 space-x-8">
        <button
          type="button"
          className="text-xs font-bold text-grey-500 underline cursor-pointer button-text"
          onClick={closeChat}
          disabled={isLoading}
        >
          {t`abort`}
        </button>
        <button
          type="button"
          className="text-xs font-bold underline cursor-pointer button-text"
          onClick={onClick}
          disabled={isLoading}
        >
          {t`yes`}
        </button>
      </div>
    </MessageSystem>
  );
};
export default MessageNoSession;

import clsx from "clsx";

const FooterCardTitle = ({ active, children, onClick }) => (
  <div
    role="presentation"
    className={clsx(
      "py-6 lg:py-10 text-sm lg:text-2xl font-semibold text-center focus:outline-none",
      active ? "lg:row-auto row-start-1 bg-green-800" : "text-grey-800 lg:text-grey bg-grey-100 lg:bg-green-800"
    )}
    onClick={onClick}
  >
    {children}
  </div>
);

export default FooterCardTitle;

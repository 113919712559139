import { useCallback, useState } from "react";
import { useRouter } from "next/router";
import request from "@/utils/request";
import { TYPE_TO_API_PATH } from "@/common/models/PaymentType";

const usePayment = () => {
  const [loading, setLoading] = useState(false);
  const { pathname: currentPath } = useRouter();

  const submitPayment = useCallback(
    async (method, amount, widgetId = null) => {
      if (loading) {
        return;
      }

      setLoading(true);

      const params = {
        [process.env.NEXT_PUBLIC_PARAM_PAYMENT_TYPE]: method,
        [process.env.NEXT_PUBLIC_PARAM_PAYMENT_REDIRECT]: currentPath,
      };

      if (widgetId) {
        params[process.env.NEXT_PUBLIC_PARAM_PAYMENT_WIDGET] = widgetId;
      }

      try {
        const urlParams = new URLSearchParams(params).toString();

        const res = await request.post(`/payment/${TYPE_TO_API_PATH[method]}`, {
          amount: parseInt(amount, 10),
          successPath: `check-payment?${urlParams}&${process.env.NEXT_PUBLIC_PARAM_PAYMENT_STATUS}=1`,
          failurePath: `check-payment?${urlParams}&${process.env.NEXT_PUBLIC_PARAM_PAYMENT_STATUS}=0`,
        });
        window.location.assign(res.data.url);
      } catch (error) {
        console.log("Ödeme yapılırken bir sorunla karşılaşıldı: ", error);
      } finally {
        setLoading(false);
      }
    },
    [loading, currentPath]
  );

  return [loading, submitPayment];
};

export default usePayment;

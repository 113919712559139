import clsx from "clsx";
import Image from "next/image";
import IconClose from "@/common/icons/close.svg";
import useNoScroll from "@/common/hook/useNoScroll";

const SIZES = {
  xs: "lg:w-[544px]",
  sm: "min-h-[392px] lg:w-[670px]",
  md: "min-h-[440px] lg:w-[801px]",
  lg: "min-h-[496px] lg:w-[896px]",
};

const Popup = ({ children, className, onClose, closeFromOutside = true, handImage = false, size = "md" }) => {
  useNoScroll();

  return (
    <div
      className="fixed inset-0 z-40 w-full h-full bg-black bg-opacity-60 flex-center"
      onClick={closeFromOutside ? onClose : undefined}
      role="presentation"
    >
      <div
        className={clsx(
          "flex overflow-y-auto flex-col items-center p-5 w-[95vw] max-h-full bg-white rounded-lg",
          SIZES[size],
          handImage && "relative"
        )}
        onClick={(e) => e.stopPropagation()}
        role="presentation"
      >
        {onClose && (
          <button
            type="button"
            className="flex-shrink-0 self-end mb-5 w-8 h-8 rounded-full button-icon"
            onClick={onClose}
            aria-label="Close Popup"
          >
            <IconClose className="w-4 h-4" />
          </button>
        )}
        <div className={clsx("flex-col flex-grow w-full flex-center", className)}>{children}</div>
        {/* For close button height */}
        {onClose && <div className="mt-5 h-8" />}
        {/* TODO : CHANGE HAND LOCATION */}
        {handImage && (
          <div className="flex absolute bottom-0 left-10 text-3xl text-red">
            <Image src="/footer/hand.png" width={73} height={183.56} alt="Vialantis Logo inside a hand" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Popup;

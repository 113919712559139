import clsx from "clsx";
import Avatar from "@/components/common/Avatar";
import styles from "./index.module.css";

const Message = ({ author, message, image, isMine, start, end, showTimestamp, createdAt }) => (
  <div className="flex flex-col">
    {showTimestamp && <div className="self-center mt-2.5 text-xs font-bold text-grey-600 uppercase">{createdAt}</div>}
    <div className={clsx(styles.container, isMine && styles.isMine, end && styles.end, start && styles.start)}>
      <div className={styles.messageBox} title={createdAt}>
        {message}
      </div>
      {end && <Avatar image={image} author={author} className="flex-shrink-0 w-8 h-8 text-sm" />}
    </div>
  </div>
);

export default Message;

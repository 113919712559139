import { useCallback, useEffect, useRef, useState } from "react";
import Recorder from "js-audio-recorder";
import useTimer from "@/common/hook/useTimer";
import convertWAVToMP3 from "@/utils/convertWAVToMP3";

const useRecorder = () => {
  const stopTimerRef = useRef(null);
  const [audioBlob, setAudioBlob] = useState(null);
  const [recorder, setRecorder] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [seconds, startTimer, stopTimer, resetTimer] = useTimer();

  const stopRecording = useCallback(() => {
    if (recorder) {
      clearTimeout(stopTimerRef.current);
      setAudioBlob(convertWAVToMP3(recorder.getWAV(), recorder));
      stopTimer();
      setIsRecording(false);
    }
  }, [recorder, stopTimer]);

  const startRecording = useCallback(() => {
    recorder.start().then(() => {
      startTimer();
      stopTimerRef.current = setTimeout(stopRecording, process.env.NEXT_PUBLIC_MAX_RECORD_SECONDS * 1000);
      setIsRecording(true);
    });
  }, [recorder, startTimer, stopRecording]);

  const toggleRecording = useCallback(() => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  }, [isRecording, startRecording, stopRecording]);

  const resetRecording = useCallback(() => {
    setAudioBlob(null);
    resetTimer();
  }, [setAudioBlob, resetTimer]);

  useEffect(() => {
    if (recorder === null) {
      setRecorder(new Recorder({ sampleBit: 16, sampleRate: 16000, numChannel: 1 }));
    }

    return () => recorder?.destroy();
  }, [recorder]);

  useEffect(() => () => clearTimeout(stopTimerRef.current), []);

  return [audioBlob, seconds, isRecording, toggleRecording, resetRecording];
};

export default useRecorder;

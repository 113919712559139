import { useState } from "react";
import Link from "next/link";
import useTranslation from "next-translate/useTranslation";
import FooterCardTitle from "@/components/core/Footer/FooterCardTitle";
import FooterCardContent from "@/components/core/Footer/FooterCardContent";
import FooterItem from "@/components/core/Footer/FooterItem";

const FooterCard = () => {
  const [active, setActive] = useState(false);
  const { t } = useTranslation("common");

  return (
    <>
      <div className="mb-16 lg:mb-24 text-xl lg:text-4xl font-semibold text-center">{t`footer.your-opportunities-with-us`}</div>
      <div className="grid grid-cols-2 grid-rows-auto-2 grid-flow-col lg:gap-x-6">
        <FooterCardTitle active={active} onClick={() => setActive(true)}>
          {t`footer.for-guests.title`}
        </FooterCardTitle>
        <FooterCardContent active={active} image="/footer/card-background-1.png" className="h-[13rem] lg:h-[25rem]">
          <FooterItem
            title={t`footer.for-guests.anonymous-call.title`}
            description={t`footer.for-guests.anonymous-call.description`}
          />
        </FooterCardContent>
        <FooterCardTitle active={!active} onClick={() => setActive(false)}>
          {t`footer.for-registered-user.title`}
        </FooterCardTitle>
        <FooterCardContent
          active={!active}
          image="/footer/card-background-2.png"
          className="min-h-[25rem] lg:min-h-[29rem]"
          after={
            <Link href="/register">
              <a className="absolute -bottom-4 left-1/2 w-max lg:text-lg -translate-x-1/2 button hover-orange">
                {t`footer.for-registered-user.sign-up-for-free`}
              </a>
            </Link>
          }
        >
          <FooterItem
            title={t("footer.for-registered-user.prize-money.title", { amount: 20 })}
            description={t`footer.for-registered-user.prize-money.description`}
          />
          <FooterItem
            title={t("footer.for-registered-user.free-minute.title", { minute: 7 })}
            description={t`footer.for-registered-user.free-minute.description`}
          />
          <FooterItem
            title={t`footer.for-registered-user.pay-secure.title`}
            description={t`footer.for-registered-user.pay-secure.description`}
          />
          <FooterItem
            title={t`footer.for-registered-user.request-callback.title`}
            description={t`footer.for-registered-user.request-callback.description`}
          />
        </FooterCardContent>
      </div>
    </>
  );
};

export default FooterCard;

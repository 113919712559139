import useConversations from "@/common/hook/header/useConversations";
import useChatChecker from "@/common/hook/header/useChatChecker";
import MessageNoBalance from "./MessageNoBalance";
import MessageNoSession from "./MessageNoSession";
import useChatReceiverId from "@/common/hook/useChatReceiverId";
import UserState from "@/common/models/UserState";

const MessageInfoSection = () => {
  const [currentReceiverId] = useChatReceiverId();
  const { currentReceiver } = useConversations(currentReceiverId);
  const { hasSession, hasNotBalance } = useChatChecker(currentReceiverId, currentReceiver?.tariff);

  if (!currentReceiver?.tariff || !UserState.ONLINE.equals(currentReceiver?.state)) {
    return null;
  }

  if (hasNotBalance) {
    return <MessageNoBalance />;
  }

  return hasSession ? null : <MessageNoSession />;
};

export default MessageInfoSection;

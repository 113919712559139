import useSWRInfinite from "swr/infinite";
import { useCallback } from "react";
import useIsChatActive from "@/common/hook/useIsChatActive";

const MESSAGES_PER_PAGE = 30;

const getKey = (isChatActive, currentReceiverId, pageIndex, previousPageData) => {
  if (!isChatActive || !currentReceiverId || (previousPageData && !previousPageData.length)) return null;
  return `/chat/${currentReceiverId}?page=${pageIndex + 1}`;
};

function mergeMessages(data) {
  if (!Array.isArray(data)) {
    return null;
  }

  const returnData = { ...data[0], messages: [] };
  data.forEach((datum) => Array.prototype.push.apply(returnData.messages, datum.messages));

  return returnData;
}

export default function useChatMessages(currentReceiverId) {
  const [isChatActive] = useIsChatActive();
  const { data, mutate, size, setSize } = useSWRInfinite(getKey.bind(null, isChatActive, currentReceiverId));

  const isReachingEnd = data?.[data.length - 1]?.messages.length < MESSAGES_PER_PAGE;

  const addMessage = useCallback(
    (message) => {
      if (!data) return;

      if (Array.isArray(data)) {
        const newData = data.slice();
        newData[0].messages.push(message);
        mutate(newData, false);

        return;
      }

      const messages = data.messages.slice();
      messages.push(message);

      mutate({ ...data, messages }, false);
    },
    [data, mutate]
  );

  return {
    messages: data ? mergeMessages(data) : null,
    addMessage,
    loadMore: isReachingEnd ? null : () => setSize(size + 1),
  };
}

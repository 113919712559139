import { memo } from "react";
import clsx from "clsx";
import dynamic from "next/dynamic";
import { DefaultSeo } from "next-seo";
import useTranslation from "next-translate/useTranslation";
import Header from "./Header";
import Footer from "./Footer";
import LiveCallPopup from "@/components/core/LiveCallPopup";
import SocketErrorPopup from "@/components/core/SocketErrorPopup";

const CookieConsentPopup = dynamic(() => import("./CookieConsentPopup"), { ssr: false });
const Meta = dynamic(() => import("./Meta"), { ssr: false });

const LayoutComponent = ({
  children,
  className,
  hasFooter = true,
  hasFooterCard = false,
  hasFooterBackground = true,
  destroyContainer = false,
  showSearch = false,
}) => {
  const { t } = useTranslation("common");

  return (
    <div className="flex flex-col min-h-screen">
      <DefaultSeo
        defaultTitle={t`meta.title`}
        description={t`meta.description`}
        titleTemplate="%s - Vialantis"
        openGraph={{
          type: "website",
          images: [{ url: "https://vialantis.com/branding-icon.png", width: 224, height: 248 }],
        }}
        twitter={{ cardType: "summary_large_image", site: "@Vialantis" }}
        additionalMetaTags={[{ name: "keywords", content: t`meta.keywords` }]}
      />
      <Meta />
      <Header showSearch={showSearch} destroyContainer={destroyContainer} />
      {/* mt-16 and mt-24 is header height */}
      <main className={clsx("flex-grow mt-16 sm:mt-24", className)}>{children}</main>
      {hasFooter && <Footer hasFooterCard={hasFooterCard} hasBackground={hasFooterBackground} />}
      <CookieConsentPopup />
      <LiveCallPopup callType="video" />
      <LiveCallPopup callType="audio" />
      <SocketErrorPopup />
    </div>
  );
};

const Layout = memo(LayoutComponent);
export default Layout;

import clsx from "clsx";
import useTranslation from "next-translate/useTranslation";
import { useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import Avatar from "@/components/common/Avatar";
import UserState from "@/common/models/UserState";
import Badge from "@/components/common/Badge";
import IconArrowRight from "@/common/icons/arrow-right.svg";
import IconChevronRight from "@/common/icons/chevron-right.svg";
import IconState from "@/common/icons/online-state.svg";

const SearchBarConsultantCard = ({ consultant, closeRoutingOnBlur, responsive }) => {
  const { t } = useTranslation();
  const [sliceCount, setSliceCount] = useState(3);
  const badgesRef = useRef(null);
  const router = useRouter();

  useEffect(() => {
    const needShrink = badgesRef.current.offsetWidth < badgesRef.current.scrollWidth;
    if (needShrink) setSliceCount(sliceCount - 1);
  }, [sliceCount]);

  return (
    <div
      className="flex justify-between items-center lg:p-1 mt-6 bg-white hover:bg-grey-100 rounded-lg cursor-pointer"
      role="presentation"
      onMouseDown={() => {
        closeRoutingOnBlur();
        router.push(`/${consultant.slug}`);
      }}
    >
      <div className={clsx("flex gap-3 items-center w-full", responsive && "lg:gap-6 lg:w-1/3")}>
        <div className="relative">
          <Avatar
            image={consultant.picture}
            author={consultant.name}
            className={clsx("flex-shrink-0 w-16 h-16 border-4 border-white", responsive && "lg:w-24 lg:h-24")}
          />
          <IconState
            className={clsx(
              "absolute bottom-0 left-1 w-6",
              responsive && "lg:bottom-1 lg:left-2 lg:w-8",
              UserState[consultant.state].textColor
            )}
          />
        </div>
        <div className="w-full min-w-0">
          <div className={clsx("font-semibold uppercase", responsive && "lg:text-xl")}>{consultant.name}</div>
          <div className="flex gap-3 items-center mt-3" ref={badgesRef}>
            {consultant.qualifications?.slice(0, sliceCount).map((qualification) => (
              <Badge color="grey" key={qualification} noWrap big={false}>
                {qualification}
              </Badge>
            ))}
          </div>
        </div>
      </div>
      <p className={clsx("hidden w-1/2 text-xs leading-5 text-grey-700", responsive && "lg:line-clamp-2")}>
        {consultant.introduction}
      </p>
      <button
        type="button"
        className={clsx(
          "hidden p-2 space-x-2 text-green hover:text-green-900 button-text",
          responsive && "lg:flex-center"
        )}
      >
        <span>{t`common:consultant-card.to-the-profile`}</span>
        <IconArrowRight />
      </button>
      <button
        className={clsx("flex-shrink-0 button-icon", responsive && "lg:hidden")}
        type="button"
        aria-label={t`common:consultant-card.to-the-profile`}
      >
        {/* lg'ler responsive'e giremedi button-icon yüzünden */}
        <IconChevronRight className="w-4 lg:w-8 h-4 lg:h-8" />
      </button>
    </div>
  );
};

export default SearchBarConsultantCard;

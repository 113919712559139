import useTranslation from "next-translate/useTranslation";
import Popup from "@/components/common/Popup";

const VideoCallPopupStop = ({ onClose, callType }) => {
  const { t } = useTranslation("common");

  return (
    <Popup size="sm" onClose={onClose}>
      <div className="flex flex-col gap-4 items-center px-8">
        <h3 className="text-xl lg:text-3xl font-semibold text-center">
          {t("live-call.terminated", { callType: t(`live-call.${callType}`) })}
        </h3>
      </div>
    </Popup>
  );
};

export default VideoCallPopupStop;

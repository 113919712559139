import { useMemo } from "react";

export default function useUserDisplayName(user) {
  return useMemo(() => {
    if (!user) {
      return "";
    }

    if (user.isConsultant && !user.isCustomer) {
      return `${user.forename} ${user.surname}`;
    }

    return user.displayName || user.username || user.email || "";
  }, [user]);
}

const TYPES = {
  PAYPAL: "paypal",
  SOFORT: "sofort",
  CREDIT_CARD: "creditcard",
};
export default TYPES;

export const TYPE_TO_API_PATH = {
  [TYPES.PAYPAL]: "charge-by-paypal",
  [TYPES.SOFORT]: "charge-by-sofort",
  [TYPES.CREDIT_CARD]: "charge-by-creditcard",
};

export default function ChatMessage(message, from, to, isMine, type = null) {
  const createdAt = Date.now();

  return {
    id: createdAt + Math.random(),
    messageFromId: from,
    messageToId: to,
    type,
    message,
    isReaded: false,
    createdAt,
    lastMessageCreatedAt: {
      date: createdAt,
      timezone_type: 3,
      timezone: "Europe/Berlin",
    },
    showMessageTime: false,
    me: isMine,
  };
}

import { forwardRef, useEffect } from "react";

/* eslint no-param-reassign: ["error", { "props": false }] */
const AutoExpandTextArea = ({ lineHeight = 24, maxRows = 5, onEnter, onInput, ...props }, ref) => {
  const handleInput = ({ target }) => {
    target.rows = 1; // HACK
    const rows = Math.floor(target.scrollHeight / lineHeight);
    target.rows = rows > maxRows ? maxRows : rows;

    if (onInput) {
      onInput(target.value);
    }
  };

  const onKeyDown = (e) => {
    if (!e.shiftKey && e.key === "Enter") {
      e.preventDefault();

      onEnter();
    }
  };

  useEffect(() => {
    ref.current.resetRows = () => {
      ref.current.rows = 1; // HACK
    };
    ref.current.resetRows();
  }, [ref]);

  return (
    <textarea
      {...props}
      ref={ref}
      rows="1"
      style={{ lineHeight: `${lineHeight}px`, maxHeight: `${lineHeight * maxRows}px` }}
      onInput={handleInput}
      onKeyDown={onEnter ? onKeyDown : null}
      className="overflow-x-hidden overflow-y-auto p-0 m-0 w-full max-w-full text-base bg-transparent focus:border-0 focus:outline-none resize-none thin-scrollbar"
    />
  );
};

export default forwardRef(AutoExpandTextArea);

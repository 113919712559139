import { useCallback, useEffect, useReducer } from "react";
import { getCache, setCache, subscribe, unsubscribe } from "@/utils/cache";

export default function useCache(key) {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const mutate = useCallback((data) => setCache(key, data), [key]);

  useEffect(() => {
    subscribe(key, forceUpdate);
    return () => unsubscribe(key, forceUpdate);
  }, [key, forceUpdate]);

  return [getCache(key), mutate];
}

import PureSelect from "@/components/common/form/PureSelect";

const Select = ({ id, children, register, label, className, labelClassName = "text-sm font-bold", inputClassName }) => (
  <div className={`flex flex-col space-y-1 ${className || ""}`}>
    <label htmlFor={id} className={labelClassName}>
      {label}
    </label>
    <PureSelect register={register} id={id} className={inputClassName}>
      {children}
    </PureSelect>
  </div>
);

export default Select;

import Image from "next/image";
import Link from "next/link";
import useTranslation from "next-translate/useTranslation";
import clsx from "clsx";
import FooterCard from "@/components/core/Footer/FooterCard";
import useUser from "@/common/hook/user/useUser";

const Footer = ({ hasFooterCard, hasBackground = true }) => {
  const { t } = useTranslation("common");
  const isAuthenticated = useUser();

  return (
    <footer className={clsx("flex-shrink-0 pt-16 lg:pt-24 w-full", hasBackground && "bg-purple-800")}>
      <div className="container">
        {hasFooterCard && <FooterCard />}
        <div className={clsx("flex items-end", hasFooterCard && "mt-18 lg:mt-9")}>
          <div className="hidden lg:flex overflow-hidden">
            <Image src="/footer/hand.png" alt="Vialantis Logo in Hand" width={103} height={259} />
          </div>
          <div className="flex flex-col flex-grow justify-between items-center lg:items-stretch mb-9 lg:ml-20 w-full lg:w-auto">
            <div className="flex items-center mb-12 lg:mb-12 ml-3 space-x-6">
              <a href="https://www.facebook.com/Vialantis" target="_blank" rel="noreferrer">
                <Image src="/facebook.svg" width={48} height={48} alt="Facebook" />
              </a>
              <a href="https://twitter.com/Vialantis" target="_blank" rel="noreferrer">
                <Image src="/twitter.svg" width={48} height={48} alt="Twitter" />
              </a>
              <a href="https://www.instagram.com/_vialantis" target="_blank" rel="noreferrer">
                <Image src="/instagram.svg" width={48} height={48} alt="Instagram" />
              </a>
            </div>
            <nav className="flex flex-col lg:flex-row gap-2 lg:gap-6 lg:items-center pb-12 lg:pb-0 lg:mt-0 w-4/5 lg:w-auto">
              <Link href="/features">
                <a className="button-text">{t`footer.features`}</a>
              </Link>
              <Link href="/imprint">
                <a className="button-text">{t`footer.imprint`}</a>
              </Link>
              <Link href="/terms-and-conditions">
                <a className="button-text">{t`footer.conditions`}</a>
              </Link>
              <Link href="/privacy-policy">
                <a className="button-text">{t`footer.privacy`}</a>
              </Link>

              <div className="flex flex-col lg:flex-row mt-6 lg:mt-0 space-y-3 lg:space-y-0 lg:space-x-3 w-full lg:w-auto">
                <Link href="/start">
                  <a className="text-white button">{t`become-consultant`}</a>
                </Link>
                {!isAuthenticated && (
                  <Link href="/login">
                    <a className="button-outline">{t`login`}</a>
                  </Link>
                )}
              </div>
            </nav>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import { useState } from "react";
import useTranslation from "next-translate/useTranslation";
import PhoneNumbers from "@/components/common/PhoneNumbers";
import AddPhoneNumberPopup from "@/components/common/AddPhoneNumberPopup";
import IconAdd from "@/common/icons/add.svg";

// TODO : I18N
const UserMenuPhoneNumbers = () => {
  const { t } = useTranslation("common");
  const [popup, setPopup] = useState(false);

  return (
    <div>
      <span className="inline-block my-3 font-semibold text-grey-700">{t`user-menu.phone-numbers`}</span>
      <PhoneNumbers />
      <button className="gap-2 px-0 mt-3 text-xs button-text flex-center" onClick={() => setPopup(true)} type="button">
        <IconAdd className="w-4 h-4" />
        neue Rufnummer hinzufügen
      </button>
      {popup && <AddPhoneNumberPopup onClose={() => setPopup(false)} />}
    </div>
  );
};

export default UserMenuPhoneNumbers;

import Popup from "./index";

/**
 * This component is deprecated. Use Popup class.
 * @deprecated
 * @param children
 * @param className
 * @param onClose
 * @param noHand
 * @param closeFromOutside
 * @returns {JSX.Element}
 * @constructor
 */
const PopupSmall = ({ children, className, onClose, noHand = false, closeFromOutside = true }) => (
  <Popup className={className} onClose={onClose} closeFromOutside={closeFromOutside} handImage={!noHand} size="sm">
    {children}
  </Popup>
);

export default PopupSmall;

import { useCallback, useState } from "react";
import useUser from "@/common/hook/user/useUser";
import request from "@/utils/request";

export default function useAddPhoneNumber(onSuccess) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { user, mutate } = useUser();

  const addNewPhoneNumber = useCallback(
    async (phoneNumber, isMain = false) => {
      if (loading) {
        return;
      }

      setLoading(true);
      setError(null);

      try {
        const response = await request.post(`/phone-numbers`, {
          main: isMain,
          number: phoneNumber,
          user: `/api/users/${user.id}`,
        });
        mutate((prev) => ({
          ...prev,
          phoneNumbers: (isMain ? prev.phoneNumbers.map((pn) => ({ ...pn, main: false })) : prev.phoneNumbers).concat(
            response.data
          ),
        }));
        if (onSuccess) onSuccess();
      } catch (err) {
        setError(err.response?.data.detail || err.message);
      } finally {
        setLoading(false);
      }
    },
    [onSuccess, loading, mutate, user.id]
  );

  return { error, loading, addNewPhoneNumber };
}

import { useMemo, useState } from "react";
import useTranslation from "next-translate/useTranslation";
import Image from "next/image";
import IconVoice from "@/common/icons/voice.svg";
import PopupSmall from "@/components/common/Popup/PopupSmall";
import AnimationThreeBalls from "@/components/common/AnimationThreeBalls";
import useRecorder from "@/common/hook/useRecorder";
import NewVoiceRecordSubmit from "./NewVoiceRecordSubmit";

const NewVoiceRecordDialog = ({ onClose }) => {
  const { t } = useTranslation("common");
  const [audioBlob, seconds, isRecording, toggleRecord, reset] = useRecorder();
  const [playing, setPlaying] = useState(false);

  const toggleRecording = () => {
    if (audioBlob) {
      setPlaying((prev) => !prev);
      return;
    }

    toggleRecord();
  };

  const { title, icon: Icon } = useMemo(() => {
    if (audioBlob) {
      return {
        title: t`voice-message.title.send-or-re-record`,
        icon: playing ? "/pause.svg" : "/play.svg",
      };
    }

    return {
      title: t(`voice-message.title.${isRecording ? "recording" : "click-button"}`),
      icon: isRecording ? AnimationThreeBalls : IconVoice,
    };
  }, [audioBlob, isRecording, playing, t]);

  return (
    <PopupSmall onClose={onClose} className="space-y-6">
      <span className="font-title text-3xl text-center uppercase">{title}</span>
      <button
        type="button"
        className="flex-shrink-0 w-16 h-16 bg-orange rounded-full shadow-3xl hover:shadow-blur-8 transition-all hover:scale-105 button-text flex-center"
        onClick={toggleRecording}
      >
        {typeof Icon === "string" ? (
          <Image src={Icon} alt="Play/Pause" width={36} height={36} />
        ) : (
          <Icon className="w-9 h-9 text-white" />
        )}
      </button>
      <span className="font-sans text-xl font-bold">{seconds}</span>
      {audioBlob && (
        <NewVoiceRecordSubmit
          onClose={onClose}
          audioBlob={audioBlob}
          playing={playing}
          stopPlaying={() => setPlaying(false)}
          reset={reset}
        />
      )}
    </PopupSmall>
  );
};

export default NewVoiceRecordDialog;

import { useEffect } from "react";
import useNoScroll from "@/common/hook/useNoScroll";
import useSocket from "@/common/hook/useSocket";

const LiveCallPopupIframe = ({ url, onClose, callType }) => {
  const socket = useSocket();
  useNoScroll();

  useEffect(() => {
    const listener = (e) => {
      try {
        const message = JSON.parse(e.data);
        if (message.type === "stop") {
          if (message.payload === "redirect") {
            setTimeout(onClose, 5000);
          } else {
            socket.emit(`${callType}.call.stop`);
            onClose();
          }
        }
      } catch (_) {
        // fk
      }
    };
    window.addEventListener("message", listener);
    return () => window.removeEventListener("message", listener);
  }, [callType, onClose, socket]);

  return (
    <div className="fixed inset-0 z-40 w-full h-full bg-black/60 flex-center">
      <iframe src={url} title="Live Call" className="w-full h-full" allow="camera *;microphone *" allowFullScreen />
    </div>
  );
};

export default LiveCallPopupIframe;

import ReactHowler from "react-howler";
import { useEffect } from "react";
import useTranslation from "next-translate/useTranslation";
import useSocket from "@/common/hook/useSocket";
import useCustomer from "@/common/hook/useCustomer";
import Sounds from "@/common/models/Sounds";
import Popup from "@/components/common/Popup";

const TIMEOUT = 90 * 10000; // 90 seconds

const LiveCallPopupQuestion = ({ data: { userId: callRequestFromId, socketId }, onClose, callType }) => {
  const { t } = useTranslation("common");
  const socket = useSocket();
  const { data: customer } = useCustomer(callRequestFromId);

  const answerCall = (answer) => {
    socket.emit(`${callType}.call.answer`, { callingFrom: socketId, answer });
    onClose();
  };

  useEffect(() => {
    const timeoutId = setTimeout(onClose, TIMEOUT);

    return () => clearTimeout(timeoutId);
  }, [onClose]);

  return (
    <Popup onClose={() => answerCall(false)} closeFromOutside={false} size="sm">
      <h3 className="text-xl lg:text-4xl font-semibold">
        {t("live-call.call.title", { callType: t(`live-call.${callType}`) })}
      </h3>
      {customer ? (
        <p className="mt-4 text-sm lg:text-xl text-grey-800">
          {t("live-call.call.subtitle", { callType: t(`live-call.${callType}`), name: customer.username })}
        </p>
      ) : (
        <div className="spinner" />
      )}
      <div className="flex gap-4 items-center mt-8">
        <button className="button-text" type="button" onClick={() => answerCall(false)}>
          {t`reject`}
        </button>
        <button className="bg-green hover:bg-green-900 button" type="button" onClick={() => answerCall(true)}>
          {t`accept`}
        </button>
      </div>
      <ReactHowler src={Sounds.alarm} loop />
    </Popup>
  );
};

export default LiveCallPopupQuestion;

import ChatDialog from "@/components/core/Header/Chat/ChatDialog";
import ChatModal from "./ChatModal";
import useIsChatActive from "@/common/hook/useIsChatActive";

const Chat = () => {
  const [isChatActive] = useIsChatActive();

  return (
    <div className="flex">
      <ChatModal />
      {isChatActive && <ChatDialog />}
    </div>
  );
};

export default Chat;

import Link from "next/link";
import useTranslation from "next-translate/useTranslation";
import Image from "next/image";
import clsx from "clsx";
import Chat from "@/components/core/Header/Chat";
import Notifications from "@/components/core/Header/Notifications";
import SearchBar from "@/components/common/SearchBar";
import useHeaderScroll from "@/common/hook/header/useHeaderScroll";
import useUser from "@/common/hook/user/useUser";
import UserMenu from "./UserMenu";
import MainMenu from "./MainMenu";

const Header = ({ showSearch, destroyContainer }) => {
  const { isAuthenticated } = useUser();
  const [canShowSearch, isScrolled] = useHeaderScroll(showSearch);
  const { t } = useTranslation("common");

  return (
    <header
      className={clsx(
        "flex fixed top-0 z-40 justify-center items-center w-full h-16 sm:h-24 bg-purple-800 transition duration-300 ease-linear",
        isScrolled && "shadow"
      )}
    >
      <section
        className={clsx("flex justify-between items-center w-full", destroyContainer ? "mx-4 lg:mx-6" : "container")}
      >
        <div className="flex flex-shrink-0 gap-1 lg:gap-2 items-center">
          <Link href="/">
            <a className="block relative w-[117.85px] lg:w-[176.77px] h-[30px] lg:h-[50px] cursor-pointer">
              <Image
                src={`${process.env.NEXT_PUBLIC_STORAGE_URL}/img/vialantis-logo.svg`}
                alt="Vialantis Logo"
                layout="fill"
                quality={100}
              />
            </a>
          </Link>
          <div className="p-1 lg:px-2 text-xs font-semibold text-white bg-grey-500 rounded-lg">BETA</div>
        </div>
        {showSearch && canShowSearch && <SearchBar isInHeader />}
        <nav className="flex items-center space-x-2">
          {!isAuthenticated && (
            <>
              <Link href="/login">
                <a className="md:hidden button-outline">{t`login`}</a>
              </Link>
              <Link href="/login">
                <a className="hidden md:inline button-text">{t`login`}</a>
              </Link>
              <Link href="/register">
                <a className="hidden md:inline button">{t`register`}</a>
              </Link>
            </>
          )}
          {isAuthenticated && <Chat />}
          <Notifications />
          {isAuthenticated && <UserMenu />}
          <MainMenu />
        </nav>
      </section>
    </header>
  );
};
export default Header;

import { useState } from "react";
import useTranslation from "next-translate/useTranslation";
import IconVoice from "@/common/icons/voice.svg";
import NewVoiceRecordDialog from "@/components/common/NewVoiceRecordDialog";

const NewVoiceRecord = () => {
  const { t } = useTranslation("common");
  const [popup, setPopup] = useState(false);

  return (
    <div className="w-full">
      <button
        type="button"
        className="sm:py-1.5 sm:px-2 space-x-2 text-grey-600 hover:text-green-900 button-text flex-center"
        onClick={() => setPopup(true)}
      >
        <IconVoice className="w-4 h-4" />
        <span className="font-semibold capitalize">{t("voice-message.new")}</span>
      </button>
      {popup && <NewVoiceRecordDialog onClose={() => setPopup(false)} />}
    </div>
  );
};

export default NewVoiceRecord;

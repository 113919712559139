import clsx from "clsx";

const TYPE = {
  purple: "font-semibold text-purple bg-purple-900 border border-purple",
  grey: "text-grey-800 bg-purple-800 cursor-default",
};

const Badge = ({ children, color = "purple", big = true, noWrap = false }) => (
  <span
    className={clsx("py-1 px-3 text-xs rounded", TYPE[color], big && "lg:text-base", noWrap && "whitespace-nowrap")}
  >
    {children}
  </span>
);

export default Badge;

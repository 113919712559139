import { useCallback, useEffect, useState } from "react";
import { mutate } from "swr";
import useSocket from "@/common/hook/useSocket";
import LiveCallPopupQuestion from "./LiveCallPopupQuestion";
import LiveCallPopupIframe from "./LiveCallPopupIframe";
import LiveCallPopupStop from "./LiveCallPopupStop";
import useUser from "@/common/hook/user/useUser";
import encodeGetParams from "@/utils/encodeGetParams";
import RatingDialog from "@/components/common/RatingDialog";

const TYPES = { CALLING: 0, URL: 1, STOP: 2 };

// TODO : DYNAMIC IMPORT
/**
 * @param callType video / audio
 * @returns {JSX.Element|null}
 * @constructor
 */
const LiveCallPopup = ({ callType = "video" }) => {
  const [[type, payload], setData] = useState([]);
  const socket = useSocket();
  const { user = {} } = useUser();
  const onClose = useCallback(() => setData([]), []);

  useEffect(() => {
    socket.on(`${callType}.call`, (data) => setData([TYPES.CALLING, data]));
    socket.on(`${callType}.call.roomId`, (roomId) => {
      const queryParams = {
        identId: user.id,
        name: user.displayName || user.username,
        image: `${process.env.NEXT_PUBLIC_STORAGE_URL}/consultants/${user.picture}`,
      };
      if (user.picture) queryParams.image = `${process.env.NEXT_PUBLIC_STORAGE_URL}/consultants/${user.picture}`;
      setData([TYPES.URL, `https://bemycall-next-video-api.vercel.app/${roomId}?${encodeGetParams(queryParams)}`]);
    });
    socket.on(`${callType}.call.stop`, ({ phoneCallId, name, isCaller }) => {
      if (phoneCallId && name) {
        setData([TYPES.STOP, { phoneCallId, name, isCaller }]);
      } else {
        onClose();
      }
      // Balance amount 0 yapması yeni bir bakiye gelene kadar arama yapmasın!
      mutate("/me", (data) => ({ ...data, balanceAmount: 0 })).then();
    });

    return () => {
      socket.off(`${callType}.call`);
      socket.off(`${callType}.call.roomId`);
      socket.off(`${callType}.call.stop`);
    };
  }, [callType, onClose, socket, user.displayName, user.id, user.picture, user.username]);

  if (type === TYPES.CALLING) {
    return <LiveCallPopupQuestion data={payload} onClose={onClose} callType={callType} />;
  }

  if (type === TYPES.URL) {
    return <LiveCallPopupIframe url={payload} onClose={onClose} callType={callType} />;
  }

  if (type === TYPES.STOP) {
    if (payload.isCaller) {
      return (
        <RatingDialog
          onClose={onClose}
          phoneCallId={payload.phoneCallId}
          consultantName={payload.name}
          mutate={() => {}}
        />
      );
    }

    return <LiveCallPopupStop onClose={onClose} callType={callType} />;
  }

  return null;
};

export default LiveCallPopup;

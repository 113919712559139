import { useCallback, useState } from "react";
import Link from "next/link";
import useTranslation from "next-translate/useTranslation";
import PaymentForm from "@/components/common/PaymentForm";
import IconUser from "@/common/icons/user.svg";
import Sidebar from "@/components/core/Header/Sidebar";
import useUser from "@/common/hook/user/useUser";
import AuthTokenStore from "@/common/store/AuthTokenStore";
import StateDropdown from "./StateDropdown";
import NewVoiceRecord from "./NewVoiceRecord";
import IconLogout from "@/common/icons/logout.svg";
import useDashboardMenu from "@/common/hook/useDashboardMenu";
import DashboardMenuStore from "@/common/store/DashboardMenuStore";
import UserMenuPhoneNumbers from "@/components/core/Header/UserMenu/UserMenuPhoneNumbers";

const UserMenu = () => {
  const { t } = useTranslation("common");
  const { user: { balanceAmount, consultantBalanceAmount, listing, slug, isConsultant, currentUserId } = {}, mutate } =
    useUser();
  const [activeDashboardMenu] = useDashboardMenu();
  const [openPaymentForm, setOpenPaymentForm] = useState(false);
  const logoutFunc = useCallback(() => {
    AuthTokenStore.delete();
    mutate(null);
  }, [mutate]);

  const isConsultantDashboard = isConsultant && activeDashboardMenu !== DashboardMenuStore.CUSTOMER;

  return (
    <Sidebar icon={IconUser}>
      <div className="flex flex-col items-start px-1">
        {isConsultantDashboard && (
          <>
            <div className="space-y-3 w-full">
              <span className="font-semibold text-grey-700">{t("user-menu.your-status")}</span>
              <StateDropdown />
              <NewVoiceRecord />
            </div>
            <hr className="my-6 w-full border-grey-100" />
          </>
        )}
        <div>
          <div className="font-semibold text-grey-700">
            {t(`user-menu.${isConsultantDashboard ? "current-credit" : "your-credit"}`)}
          </div>
          <div className="mt-3 text-3xl font-semibold text-grey">
            {((isConsultantDashboard ? consultantBalanceAmount : balanceAmount) / 100).toFixed(2)}&euro;
          </div>
          {!isConsultantDashboard && (
            <button
              className="mt-6 button hover-orange"
              type="button"
              onClick={() => setOpenPaymentForm((old) => !old)}
            >
              {t`user-menu.recharge-credit`}
            </button>
          )}
          {!isConsultantDashboard && openPaymentForm && (
            <>
              <hr className="my-6 w-full border-grey-100" />
              <div className="space-y-3">
                <span className="font-semibold text-grey-700">{t`user-menu.recharge-credit`}</span>
                <PaymentForm widgetId={currentUserId} />
              </div>
            </>
          )}
        </div>
        <hr className="my-6 w-full border-grey-100" />
        <UserMenuPhoneNumbers />
        <hr className="my-6 w-full border-grey-100" />
        <div className="flex flex-col space-y-6 min-w-[11rem]">
          {listing && (
            <Link href={`/${slug}`}>
              <a className="button-outline">{t`user-menu.profile`}</a>
            </Link>
          )}
          <Link href="/dashboard">
            <a className="button-outline">{t`user-menu.user-account`}</a>
          </Link>
          <button
            className="gap-2 text-grey-800 hover:text-purple border-grey-800 hover:border-purple button-outline flex-center"
            type="button"
            onClick={logoutFunc}
          >
            <IconLogout />
            <span>{t("user-menu.logout")}</span>
          </button>
        </div>
      </div>
    </Sidebar>
  );
};

export default UserMenu;

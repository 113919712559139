import Message from "./Message";
import MessageNoBalance from "./MessageInfoSection/MessageNoBalance";
import MessageSystem from "./MessageSystem";

const checkType = (type) => (message) => message.type === type;
const isNormalMessage = checkType(null);
const isBalanceEnd = checkType("balanceEnd");
const isPingMessage = checkType("ping");
const isHealthMessage = checkType("health");

export default function renderMessages(data) {
  const {
    receiver: { name: receiverName, picture: receiverPicture },
    me: { name: meName, picture: mePicture },
    messages,
  } = data;
  const tempMessages = [];

  /* eslint no-continue: "off" */
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < messages.length; ++i) {
    const current = messages[i];

    if (isHealthMessage(current) || (isPingMessage(current) && current.me)) {
      continue;
    }

    const isCurrentNormal = isNormalMessage(current);
    const currentCreatedAt = new Date(current.createdAt);

    const previous = messages[i - 1];
    const next = isCurrentNormal ? messages[i + 1] : null;

    let startsSequence = true;
    let endsSequence = true;
    let showTimestamp = true;

    if (previous) {
      const previousCreatedAt = new Date(previous.createdAt);
      const isDiffInOneHour = currentCreatedAt.getTime() - previousCreatedAt.getTime() < 60 * 60 * 1000;

      if (isCurrentNormal && previous.me === current.me && isDiffInOneHour) {
        startsSequence = false;
      }

      if (isDiffInOneHour && !isHealthMessage(previous) && !isPingMessage(previous)) {
        showTimestamp = false;
      }
    }

    if (next && isNormalMessage(next)) {
      const nextCreatedAt = new Date(next.createdAt);
      const isDiffInOneHour = currentCreatedAt.getTime() - nextCreatedAt.getTime() < 60 * 60 * 1000;

      if (next.me === current.me && isDiffInOneHour) {
        endsSequence = false;
      }
    }

    if (!isCurrentNormal) {
      if (isBalanceEnd(current)) {
        tempMessages.push(<MessageNoBalance key={i} message={current.message} />);
      } else {
        tempMessages.push(
          <MessageSystem
            key={i}
            message={current.message}
            timestamp={currentCreatedAt.toLocaleString()}
            showTimestamp={showTimestamp}
          />
        );
      }
      continue;
    }

    tempMessages.push(
      <Message
        key={i}
        start={startsSequence}
        end={endsSequence}
        showTimestamp={showTimestamp}
        message={current.message}
        isMine={current.me}
        createdAt={currentCreatedAt.toLocaleString()}
        author={current.me ? meName : receiverName}
        image={current.me ? mePicture : receiverPicture}
      />
    );
  }

  return tempMessages;
}

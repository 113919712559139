import { memo, useEffect, useMemo } from "react";
import useTimer from "@/common/hook/useTimer";

const MessagesHeaderTimer = ({ connectedAt }) => {
  const diff = useMemo(() => Math.floor((Date.now() - new Date(connectedAt).getTime()) / 1000), [connectedAt]);
  const [seconds, start, stop] = useTimer(diff);

  useEffect(() => {
    start();
    return () => stop();
  }, [start, stop]);

  return <span className="text-sm">{seconds}</span>;
};

export default memo(MessagesHeaderTimer);

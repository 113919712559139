import getScrollbarWidth from "@/utils/getScrollbarWidth";

/**
 * Remove scrollbar from viewport for modals
 * @param open
 * @returns {boolean}
 */
export default function addNoScroll(open = true) {
  const headerEl = document.querySelector("header");
  if (headerEl) {
    if (open) {
      headerEl.style.paddingRight = `${getScrollbarWidth()}px`;
    } else {
      headerEl.style.removeProperty("padding-right");
    }
  }

  return document.documentElement.classList.toggle("no-scroll", open);
}

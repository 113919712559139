import { useEffect, useMemo, useRef, useState } from "react";
import useTranslation from "next-translate/useTranslation";
import AutoExpandTextArea from "@/components/common/AutoExpandTextArea";
import ChatMessage from "@/common/models/ChatMessage";
import request from "@/utils/request";
import insertIntoArray from "@/utils/insertIntoArray";
import useChatChecker from "@/common/hook/header/useChatChecker";
import useConversations from "@/common/hook/header/useConversations";
import useChatTyping from "@/common/hook/header/useChatTyping";
import useSocket from "@/common/hook/useSocket";
import useChatMessages from "@/common/hook/header/useChatMessages";
import useUser from "@/common/hook/user/useUser";
import useUserDisplayName from "@/common/hook/user/useUserDisplayName";
import useChatReceiverId from "@/common/hook/useChatReceiverId";
import IconSend from "@/common/icons/send.svg";

const MessageInput = () => {
  const [currentReceiverId] = useChatReceiverId();
  const { user } = useUser();
  const currentUserName = useUserDisplayName(user);
  const [message, setMessage] = useState("");
  const inputRef = useRef(null);
  const { t } = useTranslation("common");
  const socket = useSocket();
  const {
    conversations,
    currentReceiverIndex,
    currentReceiver,
    mutate: mutateConversations,
  } = useConversations(currentReceiverId);
  const { hasSession, hasNotBalance } = useChatChecker(currentReceiverId, currentReceiver?.tariff);
  const setTyping = useChatTyping();
  const { addMessage } = useChatMessages(currentReceiverId);
  const isChatDisabled = useMemo(() => !hasSession || hasNotBalance, [hasSession, hasNotBalance]);

  const resetMessages = () => {
    inputRef.current.resetRows();
    setMessage("");
  };

  useEffect(() => {
    resetMessages();
  }, [isChatDisabled]);

  const onSubmit = () => {
    const trimmedMessage = message.trim();
    if (isChatDisabled || trimmedMessage.length === 0 || !user.id || !currentReceiverId) {
      return;
    }

    setTyping(false);
    socket.emit("chat.message", {
      message: trimmedMessage,
      senderId: user.id,
      receiverId: currentReceiverId,
      name: currentUserName,
      image: user.image,
    });

    request.post(`/chat/${currentReceiverId}`, { message: trimmedMessage });
    // ADD MESSAGE DIRECTLY
    addMessage(ChatMessage(trimmedMessage, user.id, currentReceiverId, true));
    // SET LAST MESSAGE
    mutateConversations(
      insertIntoArray(conversations, currentReceiverIndex, {
        ...conversations[currentReceiverIndex],
        lastMessage: trimmedMessage,
      }),
      false
    );
    resetMessages();

    // FOR DONT HIDE KEYBOARD ON MOBILE
    inputRef.current.focus();
  };

  const onInput = (messageFromInput) => {
    if (isChatDisabled || !user.id) {
      return;
    }

    setTyping(true);
    setMessage(messageFromInput);
  };

  return (
    <div className="flex gap-4 items-center p-2.5 bg-blue-800">
      <div className="flex flex-grow py-2.5 px-3 min-w-0 bg-white rounded-lg border border-grey-400">
        <AutoExpandTextArea
          ref={inputRef}
          placeholder={isChatDisabled ? t`chat.not-available` : t`chat.type-message`}
          disabled={isChatDisabled}
          onEnter={onSubmit}
          onInput={onInput}
          value={message}
        />
      </div>
      <button className="w-12 h-12 button-icon" type="button" onClick={onSubmit} aria-label="Send Message">
        <IconSend className="w-6 h-6" />
      </button>
    </div>
  );
};

export default MessageInput;

import useTranslation from "next-translate/useTranslation";
import { useCallback } from "react";
import useChatChecker from "@/common/hook/header/useChatChecker";
import useConversations from "@/common/hook/header/useConversations";
import IconChevronUp from "@/common/icons/chevron-up.svg";
import IconClose from "@/common/icons/close.svg";
import MessagesHeaderTimer from "./MessagesHeaderTimer";
import useChatReceiverId from "@/common/hook/useChatReceiverId";
import { setCache } from "@/utils/cache";
import CacheKeys from "@/common/models/CacheKeys";

const MessagesHeader = ({ setMobileChat, receiverName }) => {
  const { t } = useTranslation("common");
  const closeChat = useCallback(() => setCache(CacheKeys.CHAT_ACTIVE, false), []);
  const [currentReceiverId] = useChatReceiverId();
  const { currentReceiver } = useConversations(currentReceiverId);
  const { connectedAt } = useChatChecker(currentReceiverId, currentReceiver?.tariff);

  return (
    <div className="flex justify-between items-center py-3 px-4 h-16 text-center text-grey-900 border-b border-grey-200">
      <IconChevronUp className="md:hidden w-10 h-10 -rotate-90" onClick={() => setMobileChat(false)} />
      <span className="text-base font-semibold">{receiverName || t`loading`}</span>
      <div className="flex items-center space-x-4">
        {connectedAt && <MessagesHeaderTimer connectedAt={connectedAt} />}
        <button
          type="button"
          className="w-10 h-10 rounded-full button-icon"
          onClick={closeChat}
          aria-label="Close Chat"
        >
          <IconClose className="w-5 h-5" />
        </button>
      </div>
    </div>
  );
};

export default MessagesHeader;

function timeFormat(time) {
  return time < 10 ? `0${time}` : time;
}

export default function secondsToMS(seconds) {
  if (seconds < 60) {
    return `00:${timeFormat(seconds)}`;
  }

  const minutes = Math.floor(seconds / 60);
  const secondSection = seconds % 60;
  return `${timeFormat(minutes)}:${timeFormat(secondSection)}`;
}

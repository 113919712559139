import clsx from "clsx";
import styles from "./index.module.css";

const PureSelect = ({ id, children, register, rules = {}, className, fit = false, ...rest }) => (
  <select {...rest} {...register(id, rules)} className={clsx(styles.select, className, fit ? "py-2 px-3" : "p-3")}>
    {children}
  </select>
);

export default PureSelect;

import { useCallback, useRef } from "react";
import useSocket from "@/common/hook/useSocket";
import useUser from "@/common/hook/user/useUser";
import useChatReceiverId from "@/common/hook/useChatReceiverId";

const TYPING_TIMEOUT = 1000; // 1 second

export default function useChatTyping() {
  const socket = useSocket();
  const [currentReceiverId] = useChatReceiverId();
  const { user } = useUser();

  const isTyping = useRef(false);
  const typingIntervalId = useRef(null);

  const changeStatus = useCallback(
    (value) => {
      if (isTyping.current === value) {
        return;
      }

      if (typingIntervalId.current) clearTimeout(typingIntervalId.current);
      if (value) {
        typingIntervalId.current = setTimeout(() => changeStatus(false), TYPING_TIMEOUT);
      }

      isTyping.current = value;
      socket.emit("chat.typing", {
        senderId: user?.id,
        receiverId: currentReceiverId,
        isTyping: value,
      });
    },
    [currentReceiverId, isTyping, socket, user?.id]
  );

  return changeStatus;
}

import useSWR from "swr";
import { useMemo } from "react";

export default function useConversations(currentReceiverId = null) {
  const { data: conversations, mutate } = useSWR("/chats");
  const currentReceiverIndex = useMemo(
    () => (currentReceiverId && conversations ? conversations.findIndex((c) => c.id === currentReceiverId) : -1),
    [conversations, currentReceiverId]
  );

  return {
    conversations,
    mutate,
    currentReceiverIndex,
    currentReceiver: currentReceiverIndex !== -1 && conversations[currentReceiverIndex],
  };
}

import { useCallback, useRef, useState } from "react";
import secondsToMS from "@/utils/secondsToMS";

const useTimer = (initialSeconds = 0) => {
  const intervalId = useRef(null);
  const [seconds, setSeconds] = useState(initialSeconds);

  const start = useCallback(() => {
    intervalId.current = setInterval(() => setSeconds((prev) => prev + 1), 1000);
  }, []);

  const stop = useCallback(() => clearInterval(intervalId.current), []);
  const reset = useCallback(() => {
    stop();
    setSeconds(initialSeconds);
  }, [stop, setSeconds, initialSeconds]);

  return [secondsToMS(seconds), start, stop, reset];
};

export default useTimer;

import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import clsx from "clsx";
import { Controller } from "react-hook-form";

const PureInputPhoneNumber = ({ control, id, className, buttonClass, required = true, disabled }) => (
  <Controller
    control={control}
    name={id}
    rules={{ required, minLength: 5 }}
    render={({ field: { onChange, onBlur, value } }) => (
      <PhoneInput
        disabled={disabled}
        enableSearch
        disableSearchIcon
        enableAreaCodes
        enableAreaCodeStretch
        preferredCountries={["de", "at", "ch", "tr"]}
        country="de"
        inputProps={{
          placeholder: "+49 171 1234567",
          className: clsx("py-2 px-12 input peer", className),
          id,
        }}
        buttonClass={clsx(
          "bg-white peer-focus:bg-grey-100 rounded-l-lg border peer-focus:border-blue transition-colors",
          buttonClass
        )}
        value={value}
        onChange={(val) => onChange(val)}
        onBlur={onBlur}
      />
    )}
  />
);

export default PureInputPhoneNumber;
